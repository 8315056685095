import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Page, Wrapper } from 'components/Layout';
import { TextField } from 'components/Form';
import { VouchersVerify } from '../slices/Vouchers/VouchersVerify/VouchersVerify';

export const Verify = ({ location }) => {
  const transactionId = location.search.split('voucher=')[1];

  return (
    <Page location={location}>
      <Wrapper>
        <h2>Give the gift of a memorable breakfast or lunch at Indigo Sydney.</h2>
        <h3>
          Buying gifts can be tricky, but with an Indigo Gift Card you’re giving your friends and family the opportunity
          to get something they’ll truly enjoy.
        </h3>
      </Wrapper>
      <Wrapper>
        <VouchersVerify transactionId={transactionId} />
      </Wrapper>
    </Page>
  );
};

export default Verify;
